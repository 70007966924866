import { addMinutes } from 'date-fns';
import { i18n } from 'i18next';
import invariant from 'tiny-invariant';

export const I18N_SUPPORTED_LANGUAGES = {
  en: 'en',
  es: 'es',
  fr: 'fr',
} as const;
type SupportedLanguage = keyof typeof I18N_SUPPORTED_LANGUAGES;

const I18N_FALLBACK_LANGUAGE: SupportedLanguage = I18N_SUPPORTED_LANGUAGES.en;
const I18N_DEFAULT_NAMESPACE = 'global' as const;

export const i18nDefaultConfig = {
  defaultNS: I18N_DEFAULT_NAMESPACE,
  fallbackLng: I18N_FALLBACK_LANGUAGE,
  supportedLngs: [I18N_SUPPORTED_LANGUAGES.en],
  interpolation: {
    escapeValue: false,
  },
} as const;

// Some locales may not include specific timezones/conventions if they
// don't have an extension/region, resulting in UTC/GMT times being
// displayed instead of the user's local time.
export const languageWithLocaleExtension = (language?: string) => {
  if (!language) {
    return undefined;
  }

  return (
    (
      {
        es: 'es-US',
        fr: 'fr-CA',
      } as const
    )[language] ?? language
  );
};

export const composeI18nFormatters = (i18n: i18n) => {
  invariant(i18n.services.formatter, 'i18n formatter must be initialized');

  // formatter names must be snake case and unique. i18next will lowercase any names so camelCase will be converted to all lowercase. Eg. 'dateMedium' -> 'datemedium'

  i18n.services.formatter.addCached('date_month_day_year', (lng) => {
    const formatter = new Intl.DateTimeFormat(lng, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    return (val: Date) => formatter.format(val);
  });

  i18n.services.formatter.addCached('date_weekday_month_day_year', (lng) => {
    const formatter = new Intl.DateTimeFormat(lng, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    return (val: Date) => formatter.format(val);
  });

  i18n.services.formatter.addCached(
    'datetime_long',
    (lng, { includeTimeZone }: { includeTimeZone: boolean }) => {
      const formatter = new Intl.DateTimeFormat(
        languageWithLocaleExtension(lng),
        {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          ...(includeTimeZone && { timeZoneName: 'short' }),
        },
      );

      return (val) => formatter.format(val);
    },
  );

  i18n.services.formatter.addCached(
    'datetime_interval',
    (lng, options: { duration: number }) => {
      const formatter = new Intl.DateTimeFormat(
        languageWithLocaleExtension(lng),
        {
          hour: 'numeric',
          minute: 'numeric',
          timeZoneName: 'short',
        },
      );

      return (val: Date) =>
        formatter.formatRange(val, addMinutes(val, options.duration));
    },
  );

  i18n.services.formatter.addCached(
    'time_12h',
    (lng, { includeTimeZone }: { includeTimeZone: boolean }) => {
      const formatter = new Intl.DateTimeFormat(
        languageWithLocaleExtension(lng),
        {
          hour: 'numeric',
          minute: 'numeric',
          ...(includeTimeZone && { timeZoneName: 'short' }),
        },
      );

      return (val) => formatter.format(val);
    },
  );

  i18n.services.formatter.addCached('timezone_abbreviation', (lng) => {
    const formatter = new Intl.DateTimeFormat(
      languageWithLocaleExtension(lng),
      { hour: 'numeric', timeZoneName: 'short' },
    );

    return (val: Date) => {
      const parts = formatter.formatToParts(val);
      const timeZoneAbbreviation =
        parts.find((part) => part.type === 'timeZoneName')?.value ?? '';
      return timeZoneAbbreviation;
    };
  });

  i18n.services.formatter.addCached('timezone_offset', (lng) => {
    const formatter = new Intl.DateTimeFormat(
      languageWithLocaleExtension(lng),
      { hour: 'numeric', timeZoneName: 'longOffset' },
    );

    return (val: Date) => {
      const offsetParts = formatter.formatToParts(val);
      const utcOffsetWithGMT =
        offsetParts.find((part) => part.type === 'timeZoneName')?.value ?? '';

      return utcOffsetWithGMT.replace('GMT', '');
    };
  });

  i18n.services.formatter.addCached(
    'currency_narrow',
    (lng, options: { currency?: string; maximumFractionDigits?: number }) => {
      const { currency = 'CAD', maximumFractionDigits = 0 } = options;

      const formatter = new Intl.NumberFormat(lng, {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency,
        maximumFractionDigits,
      });

      return (val: number) => formatter.format(val);
    },
  );

  return i18n;
};
